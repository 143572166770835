@import url("https://fonts.googleapis.com/css?family=PT+Sans:400,700");
@import url("https://fonts.googleapis.com/css?family=Inconsolata:400,700");

@import 'node_modules/bootstrap/scss/variables';

// Buttons
$aex-button-text-color: #333;
$aex-button-background-color: white;
$aex-button-border-color: #ccc;
$aex-active-button-text-color: $aex-button-background-color;
$aex-active-button-background-color: $aex-button-text-color;

// Text
$aex-text-x-muted: lighten($text-muted, 20%);
$aex-text-xx-muted: lighten($text-muted, 50%);

// Feedback
$aex-error-feedback-color: #dc3545;

:root {
  /* Colors */
  --foreground-default: #08090a;
  --foreground-secondary: #41474d;

  --background-default: #ffffff;
  --background-secondary: #eb4e1c;

	--primary-default: #083b66;

	--font-default: #000000;
	--header-colour: #000000;

  /* Map Colors */
  --map-connected: #108735;
  --map-construction: #00000099;
  --map-site-survey: #aa0000;
  --map-future: #00aaffcc;

  --background-image: "";

  /* images */
  --logo: url("/assets/img/mockfno.png");
}

  /* images */
$logo: var(--logo);
$background-image: var(--background-image);

  /* Colors */
$foreground-default: var(--foreground-default);
$foreground-secondary: var(--foreground-secondary);
$header-colour: var(--header-colour);

$background-default: var(--background-default);
$background-secondary: var(--background-secondary);
$primary-default: var(--primary-default);

$font-default: var(--font-default);

  /* Map Colors */
$map-connected: var(--map-connected);
$map-construction: var(--map-construction);
$map-site-survey: var(--map-site-survey);
$map-future: var(--map-future);
