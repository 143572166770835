@import "./node_modules/angular-calendar/scss/angular-calendar.scss";
@import "./styles/imports.scss";
@import "node_modules/bootstrap/scss/bootstrap";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import '~primeng/resources/primeng.min.css';
@import '~primeicons/primeicons.css';


html, body {
	margin: 0;
	padding: 0;
	font-family: "PT Sans", sans-serif;
	color: $foreground-secondary !important;
	background: $background-secondary $background-image no-repeat fixed left;
	overflow-x: hidden;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
	color: $header-colour !important;
}

html {
  padding: 0;
  margin: 0;
  height: 100%;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
	color: $background-secondary !important;
	background-color: $background-default !important;
}

.nav-link {
	color: $font-default !important;
}

.btn.btn-link {
	font-weight: 400;
	color: $font-default !important;
	text-decoration: none !important;
	border: none !important;
}
.specific-width{
  width: 12rem;
}

.db-tree-view {
  tr {
    .expand-column {
      border: 1px solid white !important;
    }
  }
  th {
    .expand-column {
      border: 1px solid white !important;
    }
  }
  td {
    border: 1px solid white !important;
  }
}
db-angular-tree-grid {
  table {
    thead {
      tr {
        th {
          border: 1px solid white !important;
        }
      }
    }
  }
}
.ticketing-table {
  width: 100%;
  border-collapse: collapse;
  td {
    padding: 7px !important;
    border: #dee2e6 1px solid;
  }
  > thead {
    > tr {
      background: #e9ecef;
    }
    > th {
      border: 2px solid #dee2e6;
    }
  }
  > tbody {
    > tr {
      &:hover {
        background-color: #e8e8e8;
      }
    }
  }
}
tr.true {
  &:hover {
    background-color: #e8e8e8;
  }
}
.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-gap: 1px;
  background-color: #e9ecef;
  padding: 1px;
  grid-template-columns: minmax(auto, 20%) 1fr;
  > div {
    background-color: #ffffff;
  }
}
.grid-container-full {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-gap: 1px;
  background-color: #e9ecef;
  padding: 1px;
  grid-template-columns: minmax(auto, 0%) 1fr;
  > div {
    background-color: #ffffff;
  }
}
.header {
  padding: 10px;
  grid-area: 1 / 1 / span 1 / span 5;
}
.side {
  padding: 20px 5px;
  grid-area: 2 / 1 / span 1 / span 1;
}
.content {
  grid-area: 2 / 2 / span 1 / span 4;
}
.premise-content {
  grid-area: 2 / 2;
  padding: 20px;
}
.ticket-content {
  grid-area: 2 / 2;
}
.premise-container {
  margin-bottom: 50px;
}
.form-title {
  background-color: rgba(234, 236, 239);
  font-size: 1.5rem;
  border-radius: 0.25rem;
  margin: -20px -20px 0.75rem -20px !important;
  padding: 10px;
  text-align: center;
  &:hover {
    background-color: rgb(209, 209, 209);
    transition: 0.3s;
  }
}

.col-form-label label {
  width: 100px;
}

.col-form-wide-label label {
  width: 200px;
}

.form-row label {
  min-width: 120px;
}

#premiseCollapse::before {
  content: "\f0d7";
  color: #333;
  left: 25px;
  position: absolute;
  font-family: "FontAwesome";
}

#premiseCollapse[aria-expanded="false"]::before {
  content: "\f0da";
}

.form-row h2 {
  text-align: center;
  background-color: lightgray;
  width: 100%;
}

div.calendar, div.calendar:active {
  width: 2.75rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAcCAYAAAAEN20fAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAEUSURBVEiJ7ZQxToVAEIY/YCHGxN6XGOIpnpaEsBSeQC9ArZbm9TZ6ADyBNzAhQGGl8Riv4BLAWAgmkpBYkH1b8FWT2WK/zJ8ZJ4qiI6XUI3ANnGKWBnht2/ZBDRK3hgVGNsCd7/ui+JkEIrKtqurLpEWaphd933+IyI3LEIdpCYCiKD6HcuOa/nwOa0ScJEnk0BJg0UTUWJRl6RxCYEzEmomsIlPU3IPW+grIAbquy+q6fluy/28RIBeRMwDXdXMgXLj/B2uimRXpui4D9sBeRLKl+1N+L+t6RwbWrZliTTTr1oxYtzVWiTQAcRxvTX+eJMnlUDaO1vpZRO5NS0x48sIwfPc87xg4B04MCzQi8hIEwe4bl1DnFMCN2zsAAAAASUVORK5CYII=) !important;
  background-repeat: no-repeat;
  background-size: 23px;
  background-position: center;
}

div.datepicker {
  width: 2.75rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAcCAYAAAAEN20fAAAABHNCS…nlUDaO1vpZRO5NS0x48sIwfPc87xg4B04MCzQi8hIEwe4bl1DnFMCN2zsAAAAASUVORK5CYII=) !important;
  background-repeat: no-repeat;
  background-size: 23px;
  background-position: center;
}

footer{
  padding-top: 5px;
  height: 30px;
  color: rgba(14, 22, 18, 0.27);
  text-align: center;
  font-size: 10px;
}

.cal-btn-nav-right {
  float: right;

  div {
    min-width: 50px;
  }
}

.cal-btn-nav-left {
  float: left;
}

.cal-text {
  font-size: 1.3rem;
}

.inline-time {
  margin-top: -38px !important;
  margin-left: 2px !important;
}

.inline-date-time {
  margin-top: 27px;
  margin-bottom: 27px;
}

.reschedule-dialog {
  min-width: 400px;
}

.dropdown-item {
  border-bottom: 1px solid gray !important;
}

.cal-tooltip {
  font-size: 13px !important;
  font-family: Arial, serif;
}

.cal-tooltip-inner {
  max-width: 250px !important;
  padding: 8px;
  margin-left: 5px !important;
  text-align: left;
  color: #000 !important;
  background-color: #FDF1BA !important;
}

.cal-text-sub-title {
  padding: 5px;
  font-size: 12px;
  word-wrap: break-word;
}

a.cal-event-action {
  float: right !important;
  margin-right: 10px;
  color: #fff;
}

.cal-day-view {
  a.cal-event-action {
    margin-top: 5px !important;
  }

  .cal-event {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
    color: black;
  }

  .cal-text-title {
    padding: 5px !important;
    color: #fff;
    text-align: center !important;
    font-weight: bold;
  }
}

.cal-week-view {
  .cal-event {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
    color: black;
  }

  .cal-text-title {
    padding: 0 5px !important;
    color: #fff;
    text-align: center !important;
    font-weight: bold;
  }
}

.tooltip-val {
  font-weight: lighter;
}

dt {
  float:left;
  padding-right: 5px;
  font-weight: bolder;
  width: 80px;
}

dd,dt  {
  padding-top:1px;
  padding-bottom:1px;
}
$primary-color0: #7e57c2;
$primary-color1: #9c64a6;
$primary-color2: #ff0000;
$primary-color3: #ff6600;
$primary-color4: #800080;
$primary-color5: #F060D6;
$primary-color6: #008000;
$primary-color7: #CD5C5C;
$primary-color8: #b1bfca;
$primary-color9: #b1bfca;
$primary-color10: #95bf40;
$primary-color11: #0000b3;
$primary-color12: #c8a415;
$primary-color13: #c97b63;
$primary-color14: #5870cb;
$primary-color15: #006200;
$primary-color16: #707070;
$primary-color17: #c8a415;
$primary-color18: #c97b63;
$primary-color19: #c97b63;
$primary-color20: #FF0380;
$primary-color21: #FF6868;
$primary-color22: #1ECBE1;
$primary-undefined: #1e90ff;

$secondary-color0: #7e57c280;
$secondary-color1: #ffc4ff;
$secondary-color2: #ffc4c4;
$secondary-color3: #FDDDC2;
$secondary-color4: #D7AED7;
$secondary-color5: #FED3DD;
$secondary-color6: #14EE4A;
$secondary-color7: #E6C4C0;
$secondary-color8: #e3f2fd;
$secondary-color9: #e3f2fd;
$secondary-color10: #eff5e2;
$secondary-color11: #dadaff;
$secondary-color12: #f6e7ab;
$secondary-color13: #ffddc1;
$secondary-color14: #c0cfff;
$secondary-color15: #ebffeb;
$secondary-color16: #cfcfcf;
$secondary-color17: #ffff81;
$secondary-color18: #ffddc1;
$secondary-color19: #ffddc1;
$secondary-color20: #FFE8F3;
$secondary-color21: #FFE8F3;
$secondary-color22: #dcf7fa;
$secondary-undefined: #d1e8ff;

.primary-undefined {
  background-color: $primary-undefined !important;
}

.primary-group0 {
  background-color: $primary-color0 !important;
}
.primary-group1 {
  background-color: $primary-color1 !important;
}
.primary-group2 {
  background-color: $primary-color2 !important;
}
.primary-group3 {
  background-color: $primary-color3 !important;
}
.primary-group4 {
  background-color: $primary-color4 !important;
}
.primary-group5 {
  background-color: $primary-color5 !important;
}
.primary-group6 {
  background-color: $primary-color6 !important;
}
.primary-group7 {
  background-color: $primary-color7 !important;
}
.primary-group8 {
  background-color: $primary-color8 !important;
}
.primary-group9 {
  background-color: $primary-color9 !important;
}
.primary-group10 {
  background-color: $primary-color10 !important;
}
.primary-group11 {
  background-color: $primary-color11 !important;
}
.primary-group12 {
  background-color: $primary-color12 !important;
}
.primary-group13 {
  background-color: $primary-color13 !important;
}
.primary-group14 {
  background-color: $primary-color14 !important;
}
.primary-group15 {
  background-color: $primary-color15 !important;
}
.primary-group16 {
  background-color: $primary-color16 !important;
}
.primary-group17 {
  background-color: $primary-color17 !important;
}
.primary-group18 {
  background-color: $primary-color18 !important;
}
.primary-group19 {
  background-color: $primary-color19 !important;
}
.primary-group20 {
  background-color: $primary-color20 !important;
}
.primary-group21 {
  background-color: $primary-color21 !important;
}
.primary-group22 {
  background-color: $primary-color22 !important;
}

a, a:hover, a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

#sidebar {
  background: #fff;
  transition: all 0.3s;
  padding: 20px;
}

#sidebar ul p {
  color: #fff;
  padding: 10px;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
}

.cal-drag-active .cal-hour-segment {
  pointer-events: none;
}

.wrapper {
  display: flex;
  align-items: stretch;
}

#sidebar {
  min-width: 250px;
  max-width: 250px;
  min-height: 100vh;
}

#sidebar.active {
  margin-left: -250px;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.div-centered {
  text-align: left;
  margin: auto;
}

/* Sortable table demo */

th[sortable] {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}

th[sortable].desc:before, th[sortable].asc:before {
  content: '';
  display: block;
  width: 22px;
  height: 22px;
  float: left;
  margin-left: -22px;
}

th[sortable].desc:before {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}


/* Complete table demo */
.ngb-highlight {
  background-color: yellow;
}

/*#region custom buttons***************************************************************************/
.btn {
	border: 1px solid $background-secondary !important;
}

.btn-primary,
.btn-outline-primary {
	color: $background-default !important;
	background-color: $background-secondary !important;
	border-color: $background-secondary !important;
}

.btn.btn-default,
.btn-outline-default
.ui-widget-content a {
	color: $background-secondary !important;
	background-color: $background-default !important;
	border-color: $background-secondary !important;

	&:hover {
		color: $background-default;
		background-color: $background-secondary;
		border-color: $background-secondary;
	}
}

.btn-danger {
	&:active, &.active {
		color: $background-default;
		background-color: $background-secondary;
		border-color: $background-secondary;

		&:focus {
			color: $background-default;
			background-color: $background-secondary;
			border-color: $background-secondary;
		}

		&:hover {
			color: $background-default;
			background-color: $background-secondary;
			border-color: $background-secondary;
		}
	}
}

.open > .dropdown-toggle.btn-danger {
	color: $background-default;
	background-color: $background-secondary;
	border-color: $background-secondary;

	&:hover {
		color: $background-default;
		background-color: $background-secondary;
		border-color: $background-secondary;
	}

	&.focus {
		color: $background-default;
		background-color: $background-secondary;
		border-color: $background-secondary;
	}
}

/*#endregion***************************************************************************************/

/*#region custom card******************************************************************************/
.card{
	padding-top: 0!important;
  width: 100%;
}
/*#endregion***************************************************************************************/

.text-black {
  color: #000000 !important;
}

// *** MATERIAL AUTOCOMPLETE
.mat-autocomplete-dropdown {
	.mat-form-field {
		width: 100%
	}

  .mat-form-field-wrapper {
    padding-bottom: 0px !important;
  }

	.mat-form-field-appearance-outline .mat-form-field-infix {
		padding: 0.5em 0 !important;
	}

	.mat-form-field-infix {
		border-top: 8px solid transparent !important;
	}

	span.mat-button-wrapper {
		font-size: 0.75rem !important;
	}

	.mat-form-field-appearance-outline .mat-form-field-wrapper {
		margin: 0 !important;
	}

  .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
    min-width: auto !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-thick,
  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: #D3D3D3 !important;
  }
  .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
    color: auto !important;
    background: #e9ecef !important;
  }
  .mat-input-element:disabled, .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix:after {
    color: #212529;
  }
}

// *** autocomplete panel in bootsrap modals
// bootstrap modal z-index = 1055
// boostrap backdrop z-index = 1050
.cdk-overlay-container {
  z-index: 1060 !important;
}

// *** MATERIAL TAB NAV BAR
.mat-nav-tabs {
  a.mat-tab-link:hover {
    color: $background-secondary !important;
  }
  a.mat-tab-link.mat-tab-label-active {
    background-color: $background-default !important;
    color: $background-secondary !important;
  }
  .mat-ink-bar {
    background-color: $background-secondary !important;
  }
}
